/*========== GOOGLE FONTS ==========*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
/*========== VARIABLES CSS ==========*/

:root {
  --header-height: 3.5rem;
  --nav-width: 219px;
  /*========== Colors ==========*/
  --first-color: #21b678;
  --first-color-light: #778d8f;
  --title-color: #181b1a;
  --text-color: #5d5e55;
  --text-color-light: #A5A1AA;
  --body-color: #f6fdf9;
  --container-color: #FFFFFF;
  /*========== Font and typography ==========*/
  --body-font: 'Poppins', sans-serif;
  --normal-font-size: .938rem;
  --small-font-size: .75rem;
  --smaller-font-size: .75rem;
  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;
  /*========== z index ==========*/
  --z-fixed: 100;
}

@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

/*========== BASE ==========*/

*, ::before, ::after {
  box-sizing: border-box;
  /* font-family: var(--body-font) ; */
}

body {
  margin: calc(var(--header-height) + .5rem) 0 0 0;
  padding: 1rem 1rem 0;
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  /* background-image: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.0)), url("https://inspirationfeed.com/wp-content/uploads/2015/04/blurred-background.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
}

/* .background-image {
    background: url(https://creativeu.in/login/image/login-background1.png);
    background-size: cover;
    z-index: -1; Keep the background behind the content
    height: 100%;
    filter: blur(1px);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
} */

h3 {
  margin: 0;
}

a, Link {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*========== HEADER ==========*/

.header {
  position: fixed;
  top: 5px;
  right: 2.5rem;
  width: calc(100% - 9rem);
  background-color: var(--container-color);
  box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  border-radius: 100px;
  padding: 0 1rem;
  z-index: var(--z-fixed);
  /* padding: 1rem 3rem 0 6rem; */

}

.header__container {
  display: flex;
  align-items: center;
  height: var(--header-height);
  justify-content: space-between;
}

.header__img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.header__logo {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.header__search {
  display: flex;
  padding: .40rem .75rem;
  background-color: var(--first-color-light);
  border-radius: .25rem;
}

.header__input {
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--first-color-light);
}

.header__input::placeholder {
  font-family: var(--body-font);
  color: var(--text-color);
}

.header__icon, .header__toggle {
  font-size: 1.2rem;
}

.header__toggle {
  color: var(--title-color);
  cursor: pointer;
}

.header__icons {
  font-size: 1.3rem;
  margin: .2rem;
}

.header__icons:hover {
  color: var(--first-color);
}

/*========== NAV ==========*/

.nav {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  padding: 1rem 1rem 0;
  background-color: var(--container-color);
  box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
  z-index: var(--z-fixed);
  transition: .4s;
}

.nav__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;
  overflow: auto;
  scrollbar-width: none;
  /* For mozilla */
}

/* For Google Chrome and others */

.nav__container::-webkit-scrollbar {
  display: none;
}

.nav__logo {
  font-weight: var(--font-semi-bold);
  margin-bottom: 2.5rem;
}

.nav__list, .nav__items {
  display: grid;
}

.nav__list {
  row-gap: 2.5rem;
}

.nav__items {
  row-gap: 1.5rem;
}

.nav__subtitle {
  font-size: var(--normal-font-size);
  text-transform: uppercase;
  letter-spacing: .1rem;
  color: var(--text-color-light);
}

.nav__link {
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__icon {
  font-size: 1.2rem;
  margin-right: .5rem;
}

.nav__icon__out {
  font-size: 1.3rem;
  max-width: 20px !important;
}

.nav__name {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
  white-space: nowrap;
}

.nav__logout {
  margin-top: 5rem;
}

/* Dropdown */

.nav__dropdown {
  overflow: hidden;
  max-height: 21px;
  transition: .4s ease-in-out;
}

.nav__dropdown-collapse {
  background-color: var(--first-color-light);
  border-radius: .25rem;
  margin-top: 1rem;
}

.nav__dropdown-content {
  display: grid;
  row-gap: .5rem;
  padding: .75rem 2.5rem .75rem 1.8rem;
}

.nav__dropdown-item {
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
  color: var(--text-color);
  text-decoration: none !important;
}

.nav__dropdown-item:hover {
  color: var(--first-color);
}

.nav__dropdown-icon {
  margin-left: auto;
  transition: .4s;
}

/* Show dropdown collapse */

.nav__dropdown:hover {
  max-height: 100rem;
}

/* Rotate icon arrow */

.nav__dropdown:hover .nav__dropdown-icon {
  transform: rotate(180deg);
}

/*===== Show menu =====*/

.show-menu {
  left: 0;
}

/*===== Active link =====*/

.active {
  color: var(--first-color);
}

/* ==== Click to make navbar appear ==== */
.open-nav {
  left: 0;
  padding: 1.2rem 1.5rem 0;
  width: auto;
  
}

/* ========== MEDIA QUERIES ==========*/

/* For small devices reduce search*/

@media screen and (max-width: 320px) {
  .header__search {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .header {
    right: 2.5%;
    width: 95%;
  }
}

@media screen and (min-width: 768px) {
  body {
    padding: 1rem 3rem 0 6rem;
  }
  .header {
    padding: 0 3rem 0 3rem;
  }
  .header__container {
    height: calc(var(--header-height) + .5rem);
  }
  .header__search {
    width: 300px;
    padding: .55rem .75rem;
  }
  .header__toggle {
    display: none;
  }
  .header__logo {
    display: block;
  }
  .header__img {
    width: 40px;
    height: 40px;
    order: 1;
  }
  .nav {
    left: 0;
    padding: 1.2rem 1.5rem 0;
    width: 68px;
    /* Reduced navbar */
  }
  .nav__items {
    row-gap: 1.7rem;
  }
  .nav__icon {
    font-size: 1.3rem;
    width: 20px !important;
  }
  .nav__icon__out {
    font-size: 1.3rem;
    max-width: 20px !important;
  }
  
  /* Element opacity */
  .nav__logo-name, .nav__name, .nav__subtitle, .nav__dropdown-icon {
    opacity: 0;
    transition: .3s;
  }
  /* Navbar expanded */
  .nav:hover {
    width: var(--nav-width);
  }
  /* Visible elements */
  .nav:hover .nav__logo-name {
    opacity: 1;
  }
  .nav:hover .nav__subtitle {
    opacity: 1;
  }
  .nav:hover .nav__name {
    opacity: 1;
  }
  .nav:hover .nav__dropdown-icon {
    opacity: 1;
  }
  .nav:hover .nav__icon__out {
    display: none;
  }
}


/*========== HOME ==========*/


/*========== ANIMATIONS ==========*/
.transition {
  /* transition: .95s; */
}

.popout:hover {
  /* transform: scale(1.01); */
}


/*========== WELCOME ==========*/



/*========== Team Images ==========*/
.cardmedia {
  width: 80px;
  height: 80px;
  margin: 16px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #121212;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}



