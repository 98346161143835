// @import "bourbon";
// @import "neat";

$base-duration: 500ms;

// Colors
$color-1: #2c3e50;
$color-2: #1abc9c;
$color-3: #2ecc71;

// Breakpoints
$sm: new-breakpoint(min-width 320px);
$med: new-breakpoint(min-width 768px);
$lg: new-breakpoint(min-width 1024px);

*, *:before, *:after {
	box-sizing: border-box;
	outline: none;
}


.image-block {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translate(-40px, -16px);
	// width: 300px;
	// height: 400px;
	min-width: 400px;
	// border-radius: 10px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	overflow: hidden;
	&:hover {
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
		img {
			transform: scale(1.25);
		}
		figcaption {
			bottom: 0;
		}
    h1 {
      z-index: inherit;
    }
	}
	h1 {
		position: absolute;
		top: 50px;
		left: 20px;
		margin: 0;
		padding: 0;
		color: white;
		font-size: 38px;
		line-height: 1;
    z-index: 2;
	}
	img {
		height: 100%;
		transition: 0.25s;
    object-fit: cover;
    // object-position: 20% 10%; 
	}
	figcaption {
		position: absolute;
		bottom: -70%;
		left: 0;
		width: 100%;
		// height: 50%;
		margin: 0;
		padding: 30px;
		background-color: rgba(black,0.85);
		box-shadow: 0 0 20px rgba(black,0.4);
		color: white;
		line-height: 1;
		transition: 0.25s;
		h3 {
			margin: 0 0 20px;
			padding: 0;
		}
		p {
			font-size: 14px;
			line-height: 1.75;
		}
		a {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 10px 0 0;
			padding: 10px 30px;
			background-color: $color-2;
			border: none;
			border-radius: 5px;
			color: white;
			font-size: 14px;
		}
	}
}



@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

.container-body > * {
	box-sizing: border-box;
}

.container-body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 500px;
  box-sizing: border-box;
}

.container-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
}

input[type=radio] {
  display: none;
}

.card {
  position: absolute;
  width: 60%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform .4s ease;
//   background-color: #428aa6;
}

.container-c {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cards {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.item-c {
  width: 100%;
  height: 100%;
//   border-radius: 10px;
  object-fit: cover;
}

#item-1:checked ~ .cards #card-flip-3, #item-2:checked ~ .cards #card-flip-1, #item-3:checked ~ .cards #card-flip-2 {
  transform: translatex(-40%) scale(.8);
  opacity: .4;
  z-index: 0;
}

#item-1:checked ~ .cards #card-flip-2, #item-2:checked ~ .cards #card-flip-3, #item-3:checked ~ .cards #card-flip-1 {
  transform: translatex(40%) scale(.8);
  opacity: .4;
  z-index: 0;
}

#item-1:checked ~ .cards #card-flip-1, #item-2:checked ~ .cards #card-flip-2, #item-3:checked ~ .cards #card-flip-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;
  
	div {
		box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
	}
}


.container-body-2 > * {
	box-sizing: border-box;
}

.container-body-2 {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 500px;
  box-sizing: border-box;
}

.container-body-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
}

.card-2 {
  position: absolute;
  width: 60%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform .4s ease;
//   background-color: #428aa6;
}

.container-c-2 {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cards-2 {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.item-c-2 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

#item-1-2:checked ~ .cards-2 #card-flip-3-2, #item-2-2:checked ~ .cards-2 #card-flip-1-2, #item-3-2:checked ~ .cards-2 #card-flip-2-2 {
  transform: translatex(-40%) scale(.8);
  opacity: .4;
  z-index: 0;
}

#item-1-2:checked ~ .cards-2 #card-flip-2-2, #item-2-2:checked ~ .cards-2 #card-flip-3-2, #item-3-2:checked ~ .cards-2 #card-flip-1-2 {
  transform: translatex(40%) scale(.8);
  opacity: .4;
  z-index: 0;
}

#item-1-2:checked ~ .cards-2 #card-flip-1-2, #item-2-2:checked ~ .cards-2 #card-flip-2-2, #item-3-2:checked ~ .cards-2 #card-flip-3-2 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;
  
	div {
		box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
	}
}


.article-header {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
}

.band {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  
  display: grid;
  
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  
  @media (min-width: 30em) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (min-width: 60em) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.insert {
  background: white;
  text-decoration: none;
  color: #444;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  
  // sets up hover state
  position: relative;
  top: 0;
  transition: all .1s ease-in;
    
  &:hover {
    top: -2px;
    box-shadow: 0 4px 5px rgba(0,0,0,0.2);
  }
  
  article {
    padding: 20px;
    flex: 1;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  h1 {
    font-size: 20px;
    margin: 0;
    color: #333;
  }
  
  p {
    flex: 1;
    line-height: 1.4;
  }
  
  span {
    font-size: 12px;
    font-weight: bold;
    color: #999;
    text-transform: uppercase;
    letter-spacing: .05em;
    margin: 2em 0 0 0;
  }
  
  .thumb {
    padding-bottom: 60%;
    background-size: cover;
    background-position: center center;
  }
}

.insert-item-1 {
  @media (min-width: 60em) {
    grid-column: 1 / span 2;
    
    h1 {
      font-size: 24px;
    }
  }
}